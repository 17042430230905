<template>
    <div class="content">
        <div class="cards-container">
            <div class="container-footer">
                <div class="card-item">
                    <div class="card">
                        <div class="avatar" style="background-image: url(//d1aettbyeyfilo.cloudfront.net/fundamentals/28601737_1654597939J69Stefan_Irimia.webp)"></div>
                        <div class="icon">
                            <icon-quote/>
                        </div>
                        <div class="header">
                            <div class="header-name">
                                Ștefan Irimia
                            </div>
                            <div class="header-description">
                                Cofondator All About Parenting
                            </div>
                        </div>
                        <div class="description">
                            Îmi conduc afacerile prin sistemul TBF de mai mult de 5 ani. Deși colegii mei sunt în România, acest model de management <b>mi-a permis să locuiesc în Spania și Marea Britanie fără să pierd controlul afacerilor. </b>
                        </div>
                    </div>
                </div>
                <div class="card-item">
                    <div class="card">
                        <div class="avatar" style="background-image: url(//d1aettbyeyfilo.cloudfront.net/fundamentals/28601727_1654597901VAsDan_Pastiu.webp)"></div>
                        <div class="icon">
                            <icon-quote/>
                        </div>
                        <div class="header">
                            <div class="header-name">
                                Dan Paștiu
                            </div>
                            <div class="header-description">
                                Fondator Franciza Dabo Doner
                            </div>
                        </div>
                        <div class="description">
                            <b>Conducem fără micromanagement peste 1000 de angajați </b>în România, Ungaria și Marea Britanie. Totul datorită sistemului de management și metodologiei de business create de Răzvan Căzănescu. 
                        </div>
                    </div>
                </div>
                <div class="card-item">
                    <div class="card">
                        <div class="avatar" style="background-image: url(//d1aettbyeyfilo.cloudfront.net/fundamentals/28601732_16545979192SWQ9CsR4ncqbrD3228hfNdNRlGVL13ecC34RuUSN9k_1.webp)"></div>
                        <div class="icon">
                            <icon-quote/>
                        </div>
                        <div class="header">
                            <div class="header-name">
                                Octavian Blăjan
                            </div>
                            <div class="header-description">
                                Fondator Euroteam Spedition
                            </div>
                        </div>
                        <div class="description">
                            Folosind sistemele TBF de management create de Răzvan Căzănescu am putut <b>să dublăm cifra de afaceri an de an până la 10 Milioane de Euro</b>. Iar în 2022 suntem din nou în grafic să dublăm. 
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="questions">
            <div class="questions-container">

                <div class="question-card">
                    <div class="icon" v-if="$resize && $mq.above(501)"><icon-question/></div>
                    <div class="question-info">
                        <div class="question">
                            <span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question/></span>
                           {{ $t('footer.question_1')}}
                        </div>
                        <div class="answer">
                            {{ $t('footer.answer_1')}}
                        </div>
                    </div>
                </div>

                <div class="question-card">
                    <div class="icon" v-if="$resize && $mq.above(501)"><icon-question/></div>
                    <div class="question-info">
                        <div class="question">
                            <span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question/></span>
                            {{ $t('footer.question_2')}}
                        </div>
                        <div class="answer" v-html="$t('footer.answer_2')">
                        </div>
                    </div>
                </div>

                <div class="question-card">
                    <div class="icon" v-if="$resize && $mq.above(501)"><icon-question/></div>
                    <div class="question-info">
                        <div class="question">
                            <span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question/></span>
                            {{ $t('footer.question_3')}}
                        </div>
                        <div class="answer">
                            {{ $t('footer.answer_3')}}
                        </div>
                    </div>
                </div>

                <div class="question-card">
                    <div class="icon" v-if="$resize && $mq.above(501)"><icon-question/></div>
                    <div class="question-info">
                        <div class="question">
                            <span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question/></span>
                            {{ $t('footer.question_4')}}
                        </div>
                        <div class="answer">
                            {{ $t('footer.answer_4')}}
                        </div>
                    </div>
                </div>

                <div class="question-card">
                    <div class="icon" v-if="$resize && $mq.above(501)"><icon-question/></div>
                    <div class="question-info">
                        <div class="question">
                            <span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question/></span>
                            {{ $t('footer.question_5')}}
                        </div>
                        <div class="answer">
                            {{ $t('footer.answer_5')}}
                        </div>
                    </div>
                </div>

                <div class="question-card">
                    <div class="icon" v-if="$resize && $mq.above(501)"><icon-question/></div>
                    <div class="question-info">
                        <div class="question">
                            <span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question/></span>
                            {{ $t('footer.question_6')}}
                        </div>
                        <div class="answer">
                            {{ $t('footer.answer_6')}}
                        </div>
                    </div>
                </div>

                <div class="question-card">
                    <div class="icon" v-if="$resize && $mq.above(501)"><icon-question/></div>
                    <div class="question-info">
                        <div class="question">
                            <span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question/></span>
                            {{ $t('footer.question_7')}}
                        </div>
                        <div class="answer">
                            {{ $t('footer.answer_7')}} 
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import IconQuote from '../Icons/Quote'
    import IconQuestion from '../Icons/Question'

	export default {
		data() {
			return {
			}
		},
		components: {
            IconQuote,
            IconQuestion
		},
		mounted() {
		},
		methods: {
		}
	};
</script>