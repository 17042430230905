<template>
	<div class="modal-tbf modal-show-instance">
		<div class="container-modal opacity-page">
			<div class="header-modal">
				<div class="title">
					Detalii cont: {{ data.name }} - {{ data.master_instance_id }}
				</div>
				<div class="actions">
					<button class="btn-tbf white only-icon close-btn" @click="cancelModal"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="body-modal">
				<div class="details-subscriptions">
					<!-- <div class="title-section">Abonamente TBF</div> -->
					<div class="subscription" v-if="data.objective">
						<div class="title"><img src="/build/images/objectives.svg"> Soft Obiective</div>
						<div class="details-subscription">
							<div class="data-inline" v-if="data.objective.package">
								<div class="label">Pachet</div>
								<div class="value">{{ data.objective.package }}</div>
							</div>
							<div class="data-inline" v-if="data.objective.status">
								<div class="label">Status</div>
								<div :class="`value status-${data.objective.status}`">{{ $t(`admin_users.status_soft.${data.objective.status}`) }}</div>
							</div>
						</div>
						<div class="details-subscription">
							<div class="data-inline" v-if="data.objective.company_name">
								<div class="label">Company name</div>
								<div class="value">{{ data.objective.company_name }}</div>
							</div>
							<div class="data-inline" v-if="data.objective.cui">
								<div class="label">Cui</div>
								<div class="value">{{ data.objective.cui }}</div>
							</div>
						</div>
						<div class="details-subscription">
							<div class="data-inline" v-if="data.objective.voucher">
								<div class="label">Voucher</div>
								<div class="value">{{ data.objective.voucher }}</div>
							</div>
							<div class="data-inline" v-if="data.objective.stripe">
								<div class="label">Stripe</div>
								<div class="value">{{ data.objective.stripe }}</div>
							</div>
						</div>
						<div class="actions">
							<div class="buttons">
								<button id="changeStatusActiveObjectives" class="btn-tbf blue center" v-if="['unpaid', 'grace_period'].includes(data.objective.status)" @click="changeStatus('changeStatusActiveObjectives', 'objectives', 'active')">
									<div class="loader"></div>
									<div class="text">Activeaza</div>
								</button>
								<button id="changeStatusUnpaidObjectives" class="btn-tbf grey center" v-if="['active', 'grace_period'].includes(data.objective.status)" @click="changeStatus('changeStatusUnpaidObjectives', 'objectives', 'unpaid')">
									<div class="loader"></div>
									<div class="text">Anuleaza abonament</div>
								</button>
								<button class="btn-tbf grey center" @click="openSubModal('objectives', 'change_subscription')" v-if="data.objective.price_in_ron > 0">
									<div class="text">Modifica abonamentul</div>
								</button>
								<button class="btn-tbf grey center" @click="openSubModal('objectives', 'email_invoices')">
									<div class="text">Notificari facturi</div>
								</button>
							</div>
						</div>
					</div>
					<div class="subscription" v-if="data.procedure">
						<div class="title"><img src="/build/images/procedures.svg"> Soft Proceduri</div>
						<div class="details-subscription">
							<div class="data-inline" v-if="data.procedure.package">
								<div class="label">Pachet</div>
								<div class="value">{{ data.procedure.package }}</div>
							</div>
							<div class="data-inline" v-if="data.procedure.status">
								<div class="label">Status</div>
								<div :class="`value status-${data.procedure.status}`">{{ $t(`admin_users.status_soft.${data.procedure.status}`) }}</div>
							</div>
						</div>
						<div class="details-subscription">
							<div class="data-inline" v-if="data.procedure.company_name">
								<div class="label">Company name</div>
								<div class="value">{{ data.procedure.company_name }}</div>
							</div>
							<div class="data-inline" v-if="data.procedure.cui">
								<div class="label">Cui</div>
								<div class="value">{{ data.procedure.cui }}</div>
							</div>
						</div>
						<div class="details-subscription">
							<div class="data-inline" v-if="data.procedure.stripe">
								<div class="label">Stripe</div>
								<div class="value">{{ data.procedure.stripe }}</div>
							</div>
							<div class="data-inline" v-if="data.procedure.voucher">
								<div class="label">Voucher</div>
								<div class="value">{{ data.procedure.voucher }}</div>
							</div>
						</div>
						<div class="actions">
							<div class="buttons">
								<button id="changeStatusActiveProcedures" class="btn-tbf blue center" v-if="['unpaid', 'grace_period'].includes(data.procedure.status)" @click="changeStatus('changeStatusActiveProcedures', 'procedures', 'active')">
									<div class="loader"></div>
									<div class="text">Activeaza</div>
								</button>
								<button id="changeStatusUnpaidProcedures" class="btn-tbf grey center" v-if="['active', 'grace_period'].includes(data.procedure.status)" @click="changeStatus('changeStatusUnpaidProcedures', 'procedures', 'unpaid')">
									<div class="loader"></div>
									<div class="text">Anuleaza abonament</div>
								</button>
								<button class="btn-tbf grey center" @click="openSubModal('procedures', 'change_subscription')" v-if="data.procedure.price_in_ron > 0">
									<div class="text">Modifica abonamentul</div>
								</button>
								<button class="btn-tbf grey center" @click="openSubModal('procedures', 'email_invoices')">
									<div class="text">Notificari facturi</div>
								</button>
							</div>
						</div>
					</div>
					<div class="subscription" v-if="data.organigram">
						<div class="title"><img src="/build/images/organigrama.svg"> Soft Organigrama</div>
						<div class="details-subscription">
							<div class="data-inline" v-if="data.organigram.package">
								<div class="label">Pachet</div>
								<div class="value">{{ data.organigram.package }}</div>
							</div>
							<div class="data-inline" v-if="data.organigram.status">
								<div class="label">Status</div>
								<div :class="`value status-${data.organigram.status}`">{{ $t(`admin_users.status_soft.${data.organigram.status}`) }}</div>
							</div>
						</div>
						<div class="details-subscription">
							<div class="data-inline" v-if="data.organigram.company_name">
								<div class="label">Company name</div>
								<div class="value">{{ data.organigram.company_name }}</div>
							</div>
							<div class="data-inline" v-if="data.organigram.cui">
								<div class="label">Cui</div>
								<div class="value">{{ data.organigram.cui }}</div>
							</div>
						</div>
						<div class="details-subscription">
							<div class="data-inline" v-if="data.organigram.voucher">
								<div class="label">Voucher</div>
								<div class="value">{{ data.organigram.voucher }}</div>
							</div>
							<div class="data-inline" v-if="data.organigram.stripe">
								<div class="label">Stripe</div>
								<div class="value">{{ data.organigram.stripe }}</div>
							</div>
						</div>
						<div class="actions">
							<div class="buttons">
								<button id="changeStatusActiveOrganigram" class="btn-tbf blue center" v-if="['unpaid', 'grace_period'].includes(data.organigram.status)" @click="changeStatus('changeStatusActiveOrganigram', 'organigram', 'active')">
									<div class="loader"></div>
									<div class="text">Activeaza</div>
								</button>
								<button id="changeStatusUnpaidOrganigram" class="btn-tbf grey center" v-if="['active', 'grace_period'].includes(data.organigram.status)" @click="changeStatus('changeStatusUnpaidOrganigram', 'organigram', 'unpaid')">
									<div class="loader"></div>
									<div class="text">Anuleaza abonament</div>
								</button>

								<button class="btn-tbf grey center" @click="openSubModal('organigram', 'change_subscription')" v-if="data.organigram.price_in_ron > 0">
									<div class="text">Modifica abonamentul</div>
								</button>
								<button class="btn-tbf grey center" @click="openSubModal('organigram', 'email_invoices')">
									<div class="text">Notificari facturi</div>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="details-user">
					<div class="user">
						<!-- <div class="avatar">
							<img :src="data.avatar" v-if="data.avatar">
							<div class="user-circle" v-else>
								<icon-user />
							</div>
						</div> -->
						<div class="data">
							<div class="name">{{ data.name }}</div>
							<div class="email"><a :href="`mailto:${data.email}`">{{ data.email }}</a></div>
						</div>
					</div>
					<div class="simple-details">
						<div class="label">Master Instance ID</div>
						<div class="value">{{ data.master_instance_id }}</div>
					</div>
					<div class="simple-details" v-if="data.phone">
						<div class="label">Telefon</div>
						<div class="value">{{ data.phone }}</div>
					</div>
					<div class="simple-details">
						<div class="label">Creat la</div>
						<div class="value" style="text-transform: capitalize;">{{ data.created_at | moment('DD MMMM YYYY') }}</div>
					</div>

					<!-- <div class="delete-account">
						<button class="btn-tbf-link red"><div class="text">Sterge cont</div></button>
					</div> -->
				</div>
			</div>

			<div id="submodalContainer" class="submodal-container" v-if="subModalShow">
				<div class="overlay-submodal"></div>
				<modal-email-invoices id="submodal_email_invoices" v-if="show_submodal.email_invoices" @close_modal="closeSubModal('email_invoices')" :data="dataInstance" />
				<modal-change-subscription id="submodal_change_subscription" v-if="show_submodal.change_subscription" @close_modal="closeSubModal('change_subscription')" :data="dataInstance" />
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import IconUser from '../Icons/User'
	import ModalEmailInvoices from './ModalEmailInvoices'
	import ModalChangeSubscription from './ModalChangeSubscription'

	export default {
		data() {
			return {
                loaded: false,
                subModalShow: false,
                show_submodal: {
                	email_invoices: false,
                	change_subscription: false,
                },
                dataInstance: {}
			};
		},
        props: {
			data: Object
		},
        components: {
        	IconClose,
        	IconUser,
        	ModalEmailInvoices,
        	ModalChangeSubscription
        },
		mounted() {
			setTimeout(() => {
				$('.opacity-page').addClass('show')
                this.loaded = true
			}, 0)

			this.$root.$on('closeShowInstance', () => {
				setTimeout(() => {
					this.$emit("close_modal");
				}, 500)
			})

			console.log(this.data)
		},
        computed: {

        },
		methods: {
			cancelModal(){
				this.$emit("close_modal");
			},
			changeStatus(buttonId, soft, status){
				var btnSubmit = document.getElementById(buttonId);
				var btnSubmitLoader = document.querySelector(`#${buttonId} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonId} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				switch (soft){
					case "objectives":
						var objectivesHost = this.$auth.user().applications_host.find(el => el.name == 'Obiective')
						axios.post(`${objectivesHost.host}/api/${this.data.master_instance_id}/set-status`, {status: status, token: objectivesHost.api_key})
						.then(() =>{ 
							this.$root.$emit('syncMiniAdmin')
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.success')
								btnSubmit.classList.add('completed')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									this.$emit("close_modal");
								}, 1500)
							}, 300)
						})
						break;
					case "procedures":
						var proceduresHost = this.$auth.user().applications_host.find(el => el.name == 'Proceduri')
						axios.post(`${proceduresHost.host}/api/${this.data.master_instance_id}/set-status`, {status: status, token: proceduresHost.api_key})
						.then(() =>{ 
							this.$root.$emit('syncMiniAdmin')
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.success')
								btnSubmit.classList.add('completed')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									this.$emit("close_modal");
								}, 1500)
							}, 300)
						})
						break;
					case "organigram":
						var organigramHost = this.$auth.user().applications_host.find(el => el.name == 'Organigrama')
						axios.post(`${organigramHost.host}/api/${this.data.master_instance_id}/set-status`, {status: status, token: organigramHost.api_key})
						.then(() =>{ 
							this.$root.$emit('syncMiniAdmin')
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.success')
								btnSubmit.classList.add('completed')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									this.$emit("close_modal");
								}, 1500)
							}, 300)
						})
						break;
				}
			},
			openSubModal(softName, type){
				this.dataInstance = { master_instance_id: this.data.master_instance_id, soft: softName}
				this.subModalShow = true
				this.show_submodal[type] = true
				setTimeout(() => {
					document.getElementById('submodalContainer').classList.add('active')
					setTimeout(() => {
						document.getElementById(`submodal_${type}`).classList.add('active')
					}, 200);
				}, 0);
			},
			closeSubModal(type){
				document.getElementById(`submodal_${type}`).classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_submodal[type] = false
                    }, 200);
                }, 200);
			}
		}
	};
</script>

<style lang="scss">
    @import "../../styles/admin/show_instance.scss";
</style>